import React from 'react'

const SpotifyLogo = () => {
  return (
    <svg viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M73.5 0C32.9268 0 0 32.9268 0 73.5C0 114.073 32.9268 147 73.5 147C114.073 147 147 114.073 147 73.5C147 32.9268 114.073 0 73.5 0ZM103.345 108.146C102.1 108.146 101.329 107.76 100.173 107.079C81.6798 95.9353 60.1633 95.4611 38.9135 99.8177C37.7577 100.114 36.2462 100.588 35.3867 100.588C32.5119 100.588 30.704 98.3062 30.704 95.9056C30.704 92.853 32.5119 91.4008 34.7347 90.9266C59.0075 85.5623 83.8137 86.0365 104.975 98.6915C106.782 99.8474 107.849 100.885 107.849 103.582C107.849 106.279 105.745 108.146 103.345 108.146V108.146ZM111.317 88.7038C109.776 88.7038 108.738 88.0222 107.672 87.4591C89.1484 76.4933 61.5266 72.0774 36.9575 78.7458C35.5349 79.131 34.7643 79.5163 33.4306 79.5163C30.2595 79.5163 27.681 76.9379 27.681 73.7667C27.681 70.5956 29.2222 68.4913 32.2748 67.6319C40.5139 65.3202 48.9308 63.6012 61.2599 63.6012C80.4944 63.6012 99.0768 68.3728 113.718 77.0861C116.118 78.5087 117.067 80.3462 117.067 82.9246C117.037 86.1254 114.547 88.7038 111.317 88.7038V88.7038ZM120.504 66.1204C118.963 66.1204 118.015 65.7351 116.681 64.9645C95.5796 52.3687 57.8516 49.3458 33.4306 56.1623C32.3637 56.4587 31.03 56.9329 29.6075 56.9329C25.6954 56.9329 22.702 53.8802 22.702 49.9385C22.702 45.9079 25.1915 43.6258 27.8589 42.8552C38.2911 39.8026 49.9681 38.3504 62.6825 38.3504C84.3175 38.3504 106.99 42.8552 123.557 52.5169C125.869 53.8506 127.38 55.6881 127.38 59.2149C127.38 63.2456 124.12 66.1204 120.504 66.1204V66.1204Z"
        fill="#20CF5E"
      />
    </svg>
  )
}

export default SpotifyLogo
