import React from 'react'

const WaveIcon = ({ size = 44 }) => {
  return (
    <svg viewBox="0 0 44 44" width={size} height={size} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.44886 11.3022C7.56939 10.5191 9.25853 10.6693 10.2646 11.5001L9.11072 9.82291C8.18211 8.49854 8.51469 7.06569 9.84025 6.13589C11.1658 5.20966 14.922 7.69986 14.922 7.69986C13.985 6.36238 14.1603 4.66728 15.4977 3.72913C16.8352 2.79456 18.6805 3.11761 19.6175 4.45748L32.0387 22.0045L30.4556 37.3522L17.2441 32.5339L5.72052 15.4482C4.77522 14.1024 5.10184 12.2463 6.44886 11.3022Z"
        fill="#EF9645"
      />
      <path
        d="M3.86679 21.064C3.86679 21.064 2.51739 19.0971 4.48547 17.7489C6.45116 16.4007 7.79937 18.3664 7.79937 18.3664L14.0588 27.4951C14.2746 27.1351 14.5106 26.7799 14.7741 26.4294L6.08639 13.7615C6.08639 13.7615 4.73818 11.7958 6.70507 10.4475C8.67076 9.09934 10.019 11.065 10.019 11.065L18.1905 22.982C18.4945 22.734 18.8056 22.4849 19.1263 22.2405L9.65302 8.42344C9.65302 8.42344 8.3048 6.45775 10.2717 5.10954C12.2374 3.76132 13.5856 5.72702 13.5856 5.72702L23.0588 19.5417C23.4069 19.3283 23.7514 19.1436 24.0971 18.9481L15.2426 6.03576C15.2426 6.03576 13.8943 4.07007 15.86 2.72186C17.8257 1.37364 19.1739 3.33934 19.1739 3.33934L28.5363 16.9931L29.9596 19.0697C24.0614 23.1155 23.4999 30.7267 26.8698 35.6416C27.5433 36.625 28.5268 35.9515 28.5268 35.9515C24.4822 30.052 25.7171 23.423 31.6166 19.3784L29.8774 10.674C29.8774 10.674 29.2277 8.38053 31.52 7.72967C33.8135 7.08 34.4644 9.37351 34.4644 9.37351L36.473 15.3385C37.2693 17.7036 38.1169 20.0603 39.2386 22.2894C42.4059 28.5834 40.5141 36.4057 34.5419 40.5028C28.0273 44.9694 19.1215 43.3089 14.6537 36.7955L3.86679 21.064Z"
        fill="#FFDC5D"
      />
      <path
        d="M14.9589 38.5942C10.1907 38.5942 5.37239 33.7759 5.37239 29.0077C5.37239 28.3485 4.88961 27.8157 4.2304 27.8157C3.5712 27.8157 2.98828 28.3485 2.98828 29.0077C2.98828 36.16 7.80656 40.9783 14.9589 40.9783C15.6181 40.9783 16.1509 40.3954 16.1509 39.7362C16.1509 39.077 15.6181 38.5942 14.9589 38.5942Z"
        fill="#5DADEC"
      />
      <path
        d="M8.99867 40.9283C5.42251 40.9283 3.0384 38.5442 3.0384 34.968C3.0384 34.3088 2.50555 33.776 1.84635 33.776C1.18714 33.776 0.654297 34.3088 0.654297 34.968C0.654297 39.7362 4.23045 43.3124 8.99867 43.3124C9.65787 43.3124 10.1907 42.7795 10.1907 42.1203C10.1907 41.4611 9.65787 40.9283 8.99867 40.9283ZM29.2636 2.78259C28.6055 2.78259 28.0715 3.31663 28.0715 3.97465C28.0715 4.63266 28.6055 5.1667 29.2636 5.1667C34.0318 5.1667 38.8 9.44498 38.8 14.7031C38.8 15.3611 39.334 15.8952 39.992 15.8952C40.6501 15.8952 41.1841 15.3611 41.1841 14.7031C41.1841 8.13014 36.4159 2.78259 29.2636 2.78259Z"
        fill="#5DADEC"
      />
      <path
        d="M35.2238 0.448608C34.5658 0.448608 34.0318 0.932582 34.0318 1.59059C34.0318 2.24861 34.5658 2.83271 35.2238 2.83271C38.8 2.83271 41.134 5.48503 41.134 8.74291C41.134 9.40092 41.7169 9.93496 42.3761 9.93496C43.0353 9.93496 43.5181 9.40092 43.5181 8.74291C43.5181 4.16901 39.992 0.448608 35.2238 0.448608Z"
        fill="#5DADEC"
      />
    </svg>
  )
}

export default WaveIcon
